"use client";

import { twMerge } from "@mpay/web-tickets/src/tailwind/tailwindMerge";
import cx from "classnames";
import Link from "next/link";
import type { ComponentProps } from "react";
import * as React from "react";

type HrefType = ComponentProps<typeof Link>["href"];

type PropsType = {
  href: HrefType | string;
  children: React.ReactNode;
  applyBaseStyle?: boolean;
  applyLinkStyle?: boolean;
  target?: string;
  external?: boolean;
  prefetch?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;
export const NextLink = React.forwardRef<HTMLAnchorElement, PropsType>(
  function NextLink(props, ref) {
    const {
      href,
      children,
      applyBaseStyle = true,
      applyLinkStyle = false,
      target = undefined,
      className = "",
      external = false,
      ...rest
    } = props;

    const _target = target ?? external ? "_blank" : undefined;
    const rel = external ? "noopener noreferrer" : undefined;

    if (href.startsWith("#")) {
      return (
        <a
          className={cx(
            "tw-inline-flex tw-items-center",
            applyLinkStyle ? linkStyleClass : null,
            className
          )}
          target={_target}
          rel={rel}
          ref={ref}
          href={href}
          {...rest}
        >
          {children}
        </a>
      );
    }

    return (
      <>
        <Link
          href={href as any}
          passHref
          className={cx(
            applyBaseStyle && "tw-inline-flex tw-items-center",
            applyLinkStyle && linkStyleClass,
            className
          )}
          target={_target}
          rel={rel}
          // @ts-ignore ref
          ref={ref}
          {...rest}
        >
          <>{children}</>
        </Link>
      </>
    );
  }
);

export const linkStyleClass = `tw-cursor-pointer tw-text-blue-500 hover:tw-text-blue-700 border-0`;

export const StyledA = React.forwardRef<
  HTMLAnchorElement,
  React.HTMLAttributes<HTMLAnchorElement>
>(function StyledA({ className, ...props }, ref) {
  return (
    <a
      className={twMerge(
        "tw-cursor-pointer tw-text-blue-500 hover:tw-text-blue-700",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
