import { LocationMarkerIcon, VideoCameraIcon } from "@heroicons/react/solid";
import Tooltip from "@mpay/web-tickets/src/components/common/tooltip/Tooltip";
import { HeaderTag } from "@mpay/web-tickets/src/components/common/typography/headers";

export function Location({
  variant,
  location,
  headerLevel,
}: {
  variant: string;
  location: string | null;
  headerLevel: number;
}) {
  return (
    <div className="tw-overflow-ellipsis tw-text-gray-700 tw-text-sm">
      <HeaderTag level={headerLevel + 1} className="tw-sr-only">
        Location
      </HeaderTag>
      <div className="tw-grid tw-grid-cols-[1.5rem_1fr] tw-self-end">
        <div>
          <span>
            {variant === "online_only" ? (
              <>
                <VideoCameraIcon className="tw-h-4 -tw-mt-1 tw-mr-2 tw-inline" />
              </>
            ) : (
              <>
                <LocationMarkerIcon className="tw-h-4 -tw-mt-1 tw-mr-2 tw-inline" />
              </>
            )}
          </span>
        </div>
        <div>
          {variant === "online_only" ? (
            "Online event"
          ) : location ? (
            <Tooltip tooltip={location}>
              <>{location.split(",")[0]}</>
            </Tooltip>
          ) : (
            "No location"
          )}
        </div>
      </div>
    </div>
  );
}

export function LocationSection(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <section
      className="tw-py-2 tw-px-3 tw-border-t tw-border-gray-100"
      {...props}
    />
  );
}
