import max from "lodash/max";
import min from "lodash/min";

import { toPrice } from "./matterpay";

export const priceRange = (tickets: Array<{ price: number; fee: number }>) => {
  if (tickets.length > 0) {
    const maxTicketPrice = max(
      tickets.map((ticket) => ticket.price + ticket.fee)
    );
    const minTicketPrice = min(
      tickets.map((ticket) => ticket.price + ticket.fee)
    );

    if (minTicketPrice === maxTicketPrice) {
      return toPrice(minTicketPrice);
    } else {
      return toPrice(minTicketPrice) + " - " + toPrice(maxTicketPrice);
    }
  } else {
    return "NO TICKETS";
  }
};

export const priceRangeAsNumbers = (
  tickets: Array<{ price: number; fee: number }>
): { min: number; max: number } | null => {
  if (tickets.length > 0) {
    const maxTicketPrice =
      max(tickets.map((ticket) => ticket.price + ticket.fee)) ?? 0;
    const minTicketPrice =
      min(tickets.map((ticket) => ticket.price + ticket.fee)) ?? 0;

    return { min: minTicketPrice, max: maxTicketPrice };
  } else {
    return null;
  }
};
