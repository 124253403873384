"use client";

import { cx } from "class-variance-authority";
import * as React from "react";
import { CSSProperties } from "react";

import { smallBreakpoint } from "../../mobileSize";
import { MaybeMobile, useMaybeMobile } from "./MaybeMobileBase";

const isNotMobilePredicate = (width: number) => width >= smallBreakpoint;
export function useIsNotMobile() {
  return useMaybeMobile(isNotMobilePredicate);
}

export function NotMobile(props: {
  children: React.ReactNode;
  isSpan?: boolean;
  style?: CSSProperties;
}) {
  return (
    <MaybeMobile
      {...props}
      classIdent={cx(
        "tw-hidden",
        props.isSpan ? "sm:tw-inline" : "sm:tw-block"
      )}
      shouldShowIf={isNotMobilePredicate}
    />
  );
}

export default NotMobile;
