"use client";

import PriceRange from "@mpay/web-tickets/src/components/common/PriceRange";
import { twMerge } from "@mpay/web-tickets/src/tailwind/tailwindMerge";
import { notEmpty } from "@mpay/web-tickets/src/utilities/notEmpty";
import * as React from "react";

import { Event_EventType, BerkoFestOverride } from "../Event";

export function FloatingPriceSection({
  event,
  isSoldOut,
}: {
  event: Event_EventType;
  isSoldOut: boolean;
}) {
  if (isSoldOut) {
    return (
      <FloatingPrice className="tw-bg-gray-300 tw-text-gray-800">
        SOLD OUT
      </FloatingPrice>
    );
  }
  if (event.tickets.length > 0) {
    return (
      <FloatingPrice className="tw-bg-blue-500 tw-text-white">
        {
          // Display Ticket Prices on the list differently for Berkofest (564)
          event.client?.id === 564 && event.tickets.length === 1 ? (
            <BerkoFestOverride tickets={event.tickets.filter(notEmpty)} />
          ) : (
            <PriceRange tickets={event.tickets.filter(notEmpty)} />
          )
        }
      </FloatingPrice>
    );
  }
  return null;
}

export function FloatingPrice({
  className = "",
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge(
        "tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-semibold tw-shadow",
        className
      )}
      {...props}
    />
  );
}
