// @ts-ignore import any
import { format, utcToZonedTime } from "date-fns-tz";
import addDays from "date-fns/addDays";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import isSameMonth from "date-fns/isSameMonth";
import isSameYear from "date-fns/isSameYear";
import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import subWeeks from "date-fns/subWeeks";
import subYears from "date-fns/subYears";
import isString from "lodash/isString";

export type MaybeDate = FakeMoment | Date | string | null;
export class FakeMoment {
  constructor(private _date: Date) {
    // TODO: Why is this necessary?
    this._date = _date;
  }

  public isFakeMoment = true;
  private _timezone: string = "Europe/London";

  tz(timezone: string): FakeMoment {
    this._timezone = timezone;
    return this;
  }

  add(amount: number, type: "days") {
    if (type === "days") {
      return new FakeMoment(addDays(this._date, amount));
    }

    throw new Error("Not implemented " + type);
  }

  subtract(
    amount: number,
    type:
      | "days"
      | "day"
      | "weeks"
      | "week"
      | "months"
      | "month"
      | "years"
      | "year"
  ) {
    if (type === "days" || type === "day") {
      return new FakeMoment(subDays(this._date, amount));
    }

    if (type === "weeks" || type === "week") {
      return new FakeMoment(subWeeks(this._date, amount));
    }

    if (type === "months" || type === "month") {
      return new FakeMoment(subMonths(this._date, amount));
    }

    if (type === "years" || type === "year") {
      return new FakeMoment(subYears(this._date, amount));
    }

    throw new Error("Not implemented " + type);
  }

  isSame(other_: MaybeDate, roundedTo: "day" | "month" | "year") {
    const other = toMoment(other_);
    if (roundedTo === "day") {
      return isSameDay(this._date, other._date);
    }

    if (roundedTo === "month") {
      return isSameMonth(this._date, other._date);
    }

    if (roundedTo === "year") {
      return isSameYear(this._date, other._date);
    }

    throw new Error("Not implemented " + roundedTo);
  }

  isBefore(other_: MaybeDate) {
    const other = toMoment(other_);
    return isBefore(this._date, other._date);
  }

  isValid(): boolean {
    return isValid(this._date);
  }

  zonedDate(): Date {
    return utcToZonedTime(this._date, this._timezone);
  }

  format(theFormat: any) {
    const output = format(
      this.zonedDate(),
      theFormat.replace(/A/, "a").replace(/D/, "d"),
      {
        timeZone: this._timezone,
      }
    );

    return output;
  }

  toISOString() {
    return this.zonedDate().toISOString();
  }

  differenceInMilliseconds(other_: MaybeDate): number {
    const other = toMoment(other_);
    return differenceInMilliseconds(this._date, other._date);
  }

  getDate(): Date {
    return this._date;
  }

  toDate(): Date {
    return this._date;
  }
}

export function toMoment(inDate: MaybeDate = new Date()): FakeMoment {
  if (inDate instanceof FakeMoment) {
    return inDate;
  }
  let date = inDate;
  if (isString(date)) {
    date = parseISO(date);
  }
  if (date === null) {
    date = new Date();
  }
  return new FakeMoment(date);
}

// export const toMoment2 = (() => {
//   const moments = new Map();
//   moments.set("empty", moment());

//   return (inDate = moment(), store = moments): Moment => {
//     if (!inDate) {
//       return toMoment(moment());
//     }

//     if (inDate.isMoment) {
//       store.set(inDate.toISOString(), inDate);
//       return inDate;
//     } else {
//       const cDate = store.get(inDate);
//       if (cDate) {
//         return cDate;
//       } else {
//         const mDate = moment(inDate);
//         store.set(mDate.toISOString(), mDate);
//         store.set(inDate, mDate);

//         return mDate;
//       }
//     }
//   };
// })();

export function parseDateOrNull(date: string | null | undefined): Date | null {
  if (date === null || date === undefined) {
    return null;
  }
  return parseISO(date);
}
