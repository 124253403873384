"use client";

import * as React from "react";

import { twMerge } from "../../../tailwind/tailwindMerge";
import { tags, HeaderTagType, BaseHeaderClasses } from "./BaseHeaderClasses";

const cx = require("classnames");

const fontSize: Record<number, string> = {
  1: "2rem",
  2: "1.71rem",
  3: "1.28rem",
  4: "1.07rem",
  5: "1.00rem",
  6: "0.875rem",
  7: "0.75rem",
};

const make =
  (size: number) =>
  (
    props_: {
      className?: string;
      style?: any;
      children: React.ReactNode;
      isLight?: boolean;
    } & any
  ) => {
    const modifiers = props_.modifiers || [];
    const setDefaultFontSize = !props_.className;
    const props = {
      className: cx(
        {
          "tw-font-semibold": !props_.isLight,
        },
        props_.className
      ),
      style: {
        fontSize: setDefaultFontSize ? fontSize[size] : undefined,
        ...props_.style,
      },
      children: props_.children,
    };

    return React.createElement(`h${size}`, props);
  };

export const H1 = make(1);
export const H2 = make(2);
export const H3 = make(3);
export const H4 = make(4);
export const H5 = make(5);
export const H6 = make(6);
export const H7 = make(7);

export const HeaderContext = React.createContext(1);

export function useCurrentHeaderLevel() {
  return React.useContext(HeaderContext) ?? 1;
}

export function IncreaseHeaderLevel({
  children,
}: {
  children: React.ReactNode;
}) {
  const headerLevel = useCurrentHeaderLevel();
  return (
    <HeaderContext.Provider value={headerLevel + 1}>
      {children}
    </HeaderContext.Provider>
  );
}

export function HeaderTagAuto(props: Omit<HeaderTagProps, "level">) {
  const headerLevel = React.useContext(HeaderContext) ?? 1;
  return <HeaderTag {...props} level={headerLevel} />;
}

type HeaderTagProps = {
  level: number;
  className?: string;
  visualSize?: VisualSize;
  applyDefaultStyle?: boolean;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLHeadingElement>;
export function HeaderTag({
  level,
  applyDefaultStyle = false,
  className,
  visualSize,
  children,
  ...props
}: HeaderTagProps) {
  if (level == undefined) {
    throw new Error(`HeaderTag headerLevel undefined`);
  }

  if (level < 0) {
    throw new Error(`HeaderTag headerLevel too small (${level})`);
  }

  if (level > 5) {
    throw new Error(`HeaderTag headerLevel too large (${level})`);
  }

  const tag = tags[level - 1];

  const generatedClasses = twMerge(
    visualSize ? BaseHeaderClasses[visualSizeConverter[visualSize]] : null,
    className
  );

  return React.createElement(
    tag,
    { className: generatedClasses, ...props },
    children
  );
}

type VisualSize = "xl" | "lg" | "md" | "sm";

const visualSizeConverter: Record<VisualSize, HeaderTagType> = {
  xl: "h1",
  lg: "h2",
  md: "h3",
  sm: "h4",
};

export { tags };

export function HeaderDivider({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-relative tw-grid tw-grid-rows-[1fr_1fr]">
      {children}
      <div className="tw-border-t tw-w-full tw-mt-2" />
    </div>
  );
}
