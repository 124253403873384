import * as React from "react";
import { CSSProperties } from "react";

import { useWindowSize } from "../../../../utilities/hooks/useWindowSize";
import { notEmpty } from "../../../../utilities/notEmpty";

export const useMaybeMobile = (
  shouldShowIf: (number: number) => boolean
): boolean => {
  const [rendered, setRendered] = React.useState(false);
  React.useEffect(() => {
    if (!rendered) {
      setRendered(true);
    }
  }, []);

  let show = true;
  const { width } = useWindowSize();

  if (rendered && notEmpty(width)) {
    if (!shouldShowIf(width)) {
      show = false;
    }
  }

  return show;
};

export function MaybeMobile({
  children,
  isSpan,
  style,
  classIdent,
  shouldShowIf,
  ...props
}: {
  children: React.ReactNode;
  isSpan?: boolean;
  style?: CSSProperties;
  classIdent: string;
  shouldShowIf: (number: number) => boolean;
}) {
  const show = useMaybeMobile(shouldShowIf);

  const Type = isSpan ? "span" : "div";
  if (show) {
    return (
      <Type style={style} className={classIdent} {...props}>
        {children}
      </Type>
    );
  } else {
    return null;
  }
}
