import { priceRangeAsNumbers } from "../../utilities/priceRange";
import Price from "./Price";

export function PriceRange({
  tickets,
}: {
  tickets: Array<{ price: number; fee: number; isFreeTicket: boolean }>;
}) {
  const freeTicketsCount = tickets
    .map((t) => t.isFreeTicket)
    .filter(Boolean).length;
  const hasAFreeTicket = freeTicketsCount > 0;

  const values = priceRangeAsNumbers(tickets);
  if (values == null) {
    return <span className="tw-uppercase">No tickets</span>;
  } else {
    const { min, max } = values;
    if (min === max) {
      if (freeTicketsCount == tickets.length) {
        return <span>Free</span>;
      }

      return (
        <span>
          <Price pennies={min} />
        </span>
      );
    } else {
      const minimum = hasAFreeTicket ? (
        <span>Free</span>
      ) : (
        <span>
          Minimum: <Price pennies={min} />
        </span>
      );

      const rangeLow = hasAFreeTicket ? <>Free</> : <Price pennies={min} />;

      return (
        <>
          <span className="tw-sr-only">
            {minimum}
            <span>
              Maximum: <Price pennies={max} />
            </span>
          </span>
          <span aria-hidden>
            {rangeLow}
            <span> - </span>
            <Price pennies={max} />
          </span>
        </>
      );
    }
  }
}

export default PriceRange;
