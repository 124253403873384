//@ts-nocheck
import isArray from "lodash/isArray";

const coreUrl = "https://res.cloudinary.com/matterpay-ltd/image/upload";

const converters = {
  height: "h",
  width: "w",
  crop: "c",
  effect: "e",
  flags: "fl",
  quality: "q",
};

const flattenTransform = (t) =>
  Object.keys(t)
    .map((key) => {
      const value = t[key];
      return `${converters[key]}_${value}`;
    })
    .join(",");

export const cloudinaryImageUrl = (
  imageId,
  options_: Record<string, any> & { transformation?: Array<any> } = {},
  extraOptions_: { filetype?: string } = {}
) => {
  const options = {
    ...options_,
  };
  const extraOptions = { filetype: "jpg", ...extraOptions_ };

  let transformationBase = options.transformation || options;
  let transforms = "";

  if (isArray(transformationBase)) {
    transforms = transformationBase.map(flattenTransform).join("/");
  } else {
    transforms = flattenTransform(transformationBase);
  }

  const withCustomFiletype =
    (imageId || "white_a3um0f").replace(/\.(.*)$/, "") +
    "." +
    extraOptions.filetype;

  return `${coreUrl}/${transforms}/${withCustomFiletype}`;
};

export const cloudinaryImageUrls = (imageId, options_) => {
  return {
    jpg: cloudinaryImageUrl(imageId, options_, { filetype: "jpg" }),
    webp: cloudinaryImageUrl(imageId, options_, { filetype: "webp" }),
  };
};

export const gradientImageId = "gradient_xt7thq";
