"use client";

import cx from "classnames";
import * as React from "react";
import { CSSProperties } from "react";

import { smallBreakpoint } from "../../mobileSize";
import { MaybeMobile, useMaybeMobile } from "./MaybeMobileBase";

const isMobilePredicate = (width: number) => width < smallBreakpoint;
export function useIsMobile() {
  return useMaybeMobile(isMobilePredicate);
}

export function MobileOnly(props: {
  children: React.ReactNode;
  isSpan?: boolean;
  style?: CSSProperties;
}) {
  return (
    <MaybeMobile
      {...props}
      classIdent={cx(["sm:tw-hidden", props.isSpan ? "tw-inline" : "tw-block"])}
      shouldShowIf={isMobilePredicate}
    />
  );
}

export default MobileOnly;
