import { getBaseUrl } from "@mpay/core/src/utilities/getBaseUrl";
import { URLFriendly } from "@mpay/web-tickets/src/utilities/matterpay";

export function createFullEventUrl(
  clientVanityUrl: string,
  eventId: string,
  eventName: string
) {
  return `${getBaseUrl()}/s/${clientVanityUrl}/${eventId}/${URLFriendly(
    eventName
  )}`;
}

export function createFullEventPathname(
  clientVanityUrl: string,
  eventId: string,
  eventName: string
) {
  return `/s/${clientVanityUrl}/${eventId}/${URLFriendly(eventName)}`;
}
