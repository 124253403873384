"use client";

import { NextLink } from "@mpay/web-tickets/src/components/common/Link/NextLink";
import PriceRange from "@mpay/web-tickets/src/components/common/PriceRange";
import MobileOnly from "@mpay/web-tickets/src/components/common/helpers/mobile/MobileOnly";
import NotMobile from "@mpay/web-tickets/src/components/common/helpers/mobile/NotMobile";
import { HeaderTag } from "@mpay/web-tickets/src/components/common/typography/headers";
import { cloudinaryImageUrl } from "@mpay/web-tickets/src/utilities/cloudinary/utilities";
import { makeMissingDataError } from "@mpay/web-tickets/src/utilities/matterpay";
import { notEmpty } from "@mpay/web-tickets/src/utilities/notEmpty";
import * as React from "react";

import { createFullEventPathname } from "../../_lib/createFullEventUrl";
import { Event_EventType } from "./Event";
import { Location, LocationSection } from "./Location";
import { FloatingPriceSection } from "./_components/FloatingPriceSection";

export function EventSmall({
  event,
  headerLevel,
  dateSection,
}: {
  event: Event_EventType;
  headerLevel: number;
  dateSection: React.ReactNode;
}) {
  const baseHeaderLevel = headerLevel;
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  const eventHeaderId = `event_${event.id}`;

  let imageUrl = "/public/images/placeholders/placeholder.png";
  if (event.heroImage) {
    imageUrl = cloudinaryImageUrl(event.heroImage, {
      height: 600,
      crop: "thumb",
      flags: "progressive:semi",
      quality: "auto",
    });
  }

  const ticketsWithCapacityLeft = event.tickets
    .filter(notEmpty)
    .filter((ticket) => {
      return ticket.totalLeft ? ticket.totalLeft > 0 : true;
    });
  const isSoldOut =
    event.tickets.length > 0 &&
    (ticketsWithCapacityLeft.length === 0 || event.hasReachedTicketCap);
  const eventName = event.name || "Untitled event";
  if (!event.client) {
    throw makeMissingDataError("event.client");
  }
  const title = (
    <>
      <HeaderTag
        level={baseHeaderLevel}
        className="tw-font-semibold tw-text-lg"
        id={eventHeaderId}
      >
        <NextLink
          href={createFullEventPathname(
            event.client.vanityUrl,
            event.id,
            eventName
          )}
          className="tw-text-black tw-h-full tw-w-full"
          aria-labelledby={eventHeaderId}
          ref={linkRef}
        >
          <span className="tw-w-full tw-line-clamp-2">{eventName}</span>
        </NextLink>
      </HeaderTag>
    </>
  );

  return (
    <div
      className="tw-bg-white tw-shadow-lg tw-rounded-lg tw-h-full tw-cursor-pointer"
      onClick={(e) => {
        if (e.target !== linkRef.current) {
          linkRef.current?.click();
        }
      }}
    >
      <div className="tw-flex tw-flex-col tw-h-full">
        <div className="tw-my-2 tw-space-y-2">
          <div className="tw-flex tw-items-center tw-px-4">
            {event.heroImage ? (
              <img
                src={imageUrl}
                className="tw-ml-4 tw-mt-4 tw-h-8 tw-w-8 tw-rounded"
              />
            ) : null}
            <div className="tw-pt-2 sm:tw-flex tw-w-full sm:tw-justify-between tw-items-center tw-space-y-2 sm:tw-space-y-0">
              <div>{title}</div>
              <NotMobile>
                <div className="tw-inline-block">
                  <FloatingPriceSection event={event} isSoldOut={isSoldOut} />
                </div>
              </NotMobile>
              <MobileOnly>
                <PriceRange tickets={event.tickets.filter(notEmpty)} />
              </MobileOnly>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-px-4">{dateSection}</div>
        </div>
        <div className="tw-flex tw-flex-col">
          <LocationSection>
            <Location
              headerLevel={headerLevel}
              location={event.location}
              variant={event.variant}
            />
          </LocationSection>
        </div>
      </div>
    </div>
  );
}
