export const tags = ["h1", "h2", "h3", "h4", "h5", "h6"] as const;
export type HeaderTagType = (typeof tags)[number];

export const BaseHeaderClasses: Record<HeaderTagType, string> = {
  h1: `tw-text-[2rem] tw-font-semibold`,
  h2: `tw-text-[1.71rem] tw-font-semibold`,
  h3: `tw-text-[1.28rem] tw-font-semibold`,
  h4: `tw-text-[1.07rem] tw-font-semibold`,
  h5: ``,
  h6: ``,
};
