"use client";

import { NextLink } from "@mpay/web-tickets/src/components/common/Link/NextLink";
import Price from "@mpay/web-tickets/src/components/common/Price";
import { HeaderTag } from "@mpay/web-tickets/src/components/common/typography/headers";
import { cloudinaryImageUrl } from "@mpay/web-tickets/src/utilities/cloudinary/utilities";
import { isWebsiteInIframe } from "@mpay/web-tickets/src/utilities/iframe";
import { makeMissingDataError } from "@mpay/web-tickets/src/utilities/matterpay";
import { notEmpty } from "@mpay/web-tickets/src/utilities/notEmpty";
import * as React from "react";

import { createFullEventPathname } from "../../_lib/createFullEventUrl";
import { Location, LocationSection } from "./Location";
import { FloatingPriceSection } from "./_components/FloatingPriceSection";

export type Event_EventType = {
  id: string;
  name: string;
  hasPassed: boolean;
  hasReachedTicketCap: boolean;
  dateStart: Date | null;
  location: string | null;
  heroImage: string | null;
  variant: string;
  tickets: ({
    totalLeft: number | null;
    price: number;
    fee: number;
    isFreeTicket: boolean;
  } | null)[];
  client: {
    vanityUrl: string;
    id: number;
  } | null;
};

export function Event({
  event,
  headerLevel,
  dateSection,
}: {
  event: Event_EventType;
  headerLevel: number;
  dateSection: React.ReactNode;
}) {
  const baseHeaderLevel = headerLevel;
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  const eventHeaderId = `event_${event.id}`;

  let imageUrl = "/public/images/placeholders/placeholder.png";
  let imageBlurUrl = "/public/images/placeholders/placeholder.png";
  if (event.heroImage) {
    imageUrl = cloudinaryImageUrl(event.heroImage, {
      height: 600,
      crop: "thumb",
      flags: "progressive:semi",
      quality: "auto",
    });
    imageBlurUrl = cloudinaryImageUrl(event.heroImage, {
      transformation: [
        {
          width: 80,
          height: 24,
          crop: "thumb",
        },
        { effect: "blur:800" },
        {
          flags: "progressive:semi",
        },
      ],
      secure: true,
    });
  }

  const ticketsWithCapacityLeft = event.tickets
    .filter(notEmpty)
    .filter((ticket) => {
      return ticket.totalLeft ? ticket.totalLeft > 0 : true;
    });
  const isSoldOut =
    event.tickets.length > 0 &&
    (ticketsWithCapacityLeft.length === 0 || event.hasReachedTicketCap);
  const backgroundImage = `url('${imageUrl}'), url('${imageBlurUrl}')`;
  const eventName = event.name || "Untitled event";
  if (!event.client) {
    throw makeMissingDataError("event.client");
  }
  const title = (
    <>
      <HeaderTag
        level={baseHeaderLevel}
        className="tw-font-semibold tw-text-lg"
        id={eventHeaderId}
      >
        <NextLink
          href={`${createFullEventPathname(
            event.client.vanityUrl,
            event.id,
            eventName
          )}${isWebsiteInIframe() ? "/tickets" : ""}`}
          className="tw-text-black tw-h-full tw-w-full"
          aria-labelledby={eventHeaderId}
          ref={linkRef}
        >
          <span className="tw-w-full tw-line-clamp-2">{eventName}</span>
        </NextLink>
      </HeaderTag>
    </>
  );

  return (
    <div
      className="tw-bg-white tw-shadow-lg tw-rounded-lg tw-h-full tw-cursor-pointer"
      onClick={(e) => {
        if (e.target !== linkRef.current) {
          linkRef.current?.click();
        }
      }}
    >
      <div className="tw-flex tw-flex-col tw-h-full">
        <div
          style={{
            backgroundImage: backgroundImage,
            backgroundSize: "contain, cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            aspectRatio: "2/1",
          }}
          className="tw-flex tw-justify-end tw-items-end tw-p-4 tw-rounded-t-md tw-w-full"
        >
          <section>
            <HeaderTag level={baseHeaderLevel + 1} className="tw-sr-only">
              Ticket prices
            </HeaderTag>
            <FloatingPriceSection event={event} isSoldOut={isSoldOut} />
          </section>
        </div>
        <div className="tw-my-2 tw-space-y-4 tw-flex tw-flex-col tw-flex-grow tw-px-4">
          <div className="tw-pt-2 tw-flex tw-flex-grow tw-w-full">
            <div>{title}</div>
          </div>
          <div className="tw-flex tw-flex-col">{dateSection}</div>
        </div>
        <div className="tw-flex tw-flex-col">
          <LocationSection>
            <Location
              headerLevel={headerLevel}
              location={event.location}
              variant={event.variant}
            />
          </LocationSection>
        </div>
      </div>
    </div>
  );
}

export function BerkoFestOverride(tickets: {
  tickets: Array<{ price: number; fee: number }>;
}) {
  return (
    <>
      <Price pennies={tickets.tickets[0]!.price} /> +{" "}
      <Price pennies={tickets.tickets[0]!.fee} className="tw-opacity-80" />
    </>
  );
}

export default Event;
