import { PurchaseTicketAppliedDiscount } from "@mpay/web-tickets/app/host/(dash)/orders/[orderId]/_components/client_viewPurchaseQuery";
import { groupBy } from "lodash";

import { toMoment } from "./dates";

export const compareObjectsByDateStart = (
  left: { dateStart: Date | string | null },
  right: { dateStart: Date | string | null }
): number => {
  if (!left.dateStart) {
    return 1;
  }
  if (!right.dateStart) {
    return -1;
  }

  const l = toMoment(left.dateStart);
  const r = toMoment(right.dateStart);

  return l.differenceInMilliseconds(r);
};

export const compareObjectsByCreatedAt = (
  left: { createdAt: Date | string | null },
  right: { createdAt: Date | string | null }
) => {
  if (!left.createdAt) {
    return 1;
  }
  if (!right.createdAt) {
    return -1;
  }

  const l = toMoment(left.createdAt);
  const r = toMoment(right.createdAt);

  return (l as any) - (r as any);
};

export type GenericGroupedTicketType<T> = {
  ticketId: string;
  quantity: number;
  firstPurchaseTicket: T;
  purchaseTickets: Array<T>;
};

export const groupTicketsFromSnapshots = <
  T extends {
    ticketSnapshot?: { id: string };
    ticket: { id: string };
    appliedDiscount: PurchaseTicketAppliedDiscount | null;
  }
>(
  tickets: T[]
): Array<GenericGroupedTicketType<T>> => {
  const grouped = groupBy(tickets, (ticket) => {
    if (ticket.ticket) {
      // This is missing a test
      return (
        ticket.ticket.id.toString() +
        (ticket.appliedDiscount ? ticket.appliedDiscount.code.toString() : "")
      );
    }

    if (ticket.ticketSnapshot) {
      return ticket.ticketSnapshot.id;
    }

    throw new Error(`Could not group tickets`);
  });

  const result: Array<{
    ticketId: string;
    quantity: number;
    firstPurchaseTicket: T;
    purchaseTickets: Array<T>;
  }> = [];
  for (const groupedTicketId in grouped) {
    const group = grouped[groupedTicketId];

    result.push({
      ticketId: groupedTicketId,
      quantity: group.length,
      purchaseTickets: group,
      firstPurchaseTicket: group[0],
    });
  }

  return result;
};

/**
 * Creates an array filled with numbers between the range [min, max)
 * @param min The start number, inclusive.
 * @param max The end number, exclusive
 * @param step Step between each number, must be positive.
 * @returns {Array}
 */
export const range = (min: number, max: number, step: number = 1): number[] => {
  if (step <= 0) {
    return [];
  }

  const result: number[] = [];
  for (let i = min; i < max; i += step) {
    result.push(i);
  }
  return result;
};

export const toPrice = (pennies: number | null | undefined): string => {
  if (pennies == null) {
    return "N/A";
  }

  if (pennies !== Infinity) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(pennies / 100);
  }
  return "";
};

export const toPriceWholePounds = (
  pennies: number | null | undefined
): string => {
  if (pennies == null) {
    return "N/A";
  }

  if (pennies !== Infinity) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: 0,
    }).format(pennies / 100);
  }
  return "";
};

/**
 * converts pennies to pounds, divides by 1000 and adds "K" to the end, it also rounds to the nearest whole number
 * @constructor
 * @param {number} pennies - the amount in pennies
 * @returns {string} - the amount in pounds with a "K" at the end
 * @example toPriceWholePoundsWithThousandsAbbreviation(100000) // £1K
 * @example toPriceWholePoundsWithThousandsAbbreviation(56483) // £565
 * @example toPriceWholePoundsWithThousandsAbbreviation(239047) // £2K
 * @example toPriceWholePoundsWithThousandsAbbreviation(234872398) // £2,349K
 */
export const toPriceWholePoundsWithThousandsAbbreviation = (
  pennies: number | null | undefined
): string => {
  if (pennies == null) {
    return "N/A";
  }

  if (pennies !== Infinity && (pennies >= 100000 || pennies <= -100000)) {
    return (
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: 0,
      }).format(pennies / 100 / 1000) + "K"
    );
  }

  if (pennies !== Infinity) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: 0,
    }).format(pennies / 100);
  }
  return "";
};

export const toPounds = (pennies: number): number => {
  if (pennies !== Infinity) {
    return pennies / 100;
  }
  return 0;
};

export const parseAsJSON = (input: any): any => {
  try {
    return JSON.parse(input);
  } catch (e) {
    console.warn("Tried parsing", input);
    throw new Error(`Failed to JSON parse '${JSON.stringify(input)}'`);
  }
};

export const makeKey = (key: any, id: any): string => {
  return `${key}.${id}`;
};

export const URLFriendly = (input: string): string => {
  const friendly = input
    .replace(/[^\w-\s]/g, "")
    .trim()
    .replace(/\s/g, "-")
    .toLowerCase();

  if (friendly.length === 0) {
    return "untitled";
  } else {
    return friendly;
  }
};

export const makeMetaFriendly = (
  input: string | null,
  length: number
): string => {
  if (input && input.length > length) {
    return input.slice(0, length - 3) + "...";
  }

  return input + "";
};

export const TrueNoDeadCode = Math !== null;

export const quickStringSearch = (needle: any, haystack: any): boolean =>
  (String(haystack) || "")
    .toLowerCase()
    .indexOf((String(needle) || "").toLowerCase()) !== -1;

export const getEventIdFromSlug = (eventSlug: string): string =>
  eventSlug.slice(eventSlug.lastIndexOf("-") + 1);

export const makeMissingDataError = (identifier: string) =>
  new Error(`Missing data: ${identifier}`);
