export function isNodeDevelopment() {
  return process.env.NODE_ENV === "development";
}

export function isNodeProduction() {
  return process.env.NODE_ENV === "production";
}

export function isDeployedtoPreview() {
  return process.env.VERCEL_ENV === "preview";
}

export function isDeployedToProduction() {
  return process.env.VERCEL_ENV === "production";
}
