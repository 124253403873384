import * as React from "react";

export function Price({
  pennies,
  className,
}: {
  pennies: number | null | undefined;
  className?: string;
}) {
  if (pennies == null) {
    return null;
  }

  if (!Number.isFinite(pennies)) {
    return (
      <data value="N/A" className={className}>
        N/A
      </data>
    );
  }

  const asMoney = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(pennies / 100)
  return (
    <data value={asMoney} className={className}>
      {asMoney}
    </data>
  );
}

export default Price;
